// @ts-check

const Post = require('./PostModel');


/**
 * @param {{ type?: any; region?: any; locale?: any; title?: any; slug?: any; url?: String; topMenuPageOrder?: Number; mobileUrl?: String; }} frontmatter
 */
exports.toPost = (frontmatter) => {

    let mobileUrl = frontmatter.mobileUrl;
    
    if (!mobileUrl) {
        mobileUrl = frontmatter.locale === 'el' ?
            `/mobile/gr${frontmatter.url}` :
            `/mobile${frontmatter.url}`;
    }

    return new Post(
        frontmatter.type,
        frontmatter.slug,
        frontmatter.locale,
        frontmatter.region,
        frontmatter.title,
        frontmatter.url,
        mobileUrl,
        frontmatter.topMenuPageOrder
    );
}


