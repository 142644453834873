import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import FeaturedIslands from "../components/lists/featured"

import { getHrefLangs } from "../core/QueryTransformer"

export default function Template({ data, pageContext: { pages, locale, home } }) {

  const { regionPost, hreflangEdges, featuredLocations } = data
  const { frontmatter, html } = regionPost
  let hreflangs = getHrefLangs(hreflangEdges.edges)

  return (
    <Layout
      hreflangs={hreflangs}
      pages={pages.filter(page => page.locale === frontmatter.locale)}
      description={frontmatter.description}
      locale={locale}
      home={home}
      heading={frontmatter.heading}
    >
      <SEO
        title={frontmatter.metatitle}
        hreflangs={hreflangs}
        locale={frontmatter.locale}
        description={frontmatter.metadescription}
      />

      <div className="main-body">
      <section className="container">
        <div
          className="intro-text"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
        <FeaturedIslands islands={featuredLocations.edges}></FeaturedIslands>
      </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($url: String!, $locale: String!, $slug: String!) {
    regionPost: markdownRemark(
      frontmatter: { url: { eq: $url }, locale: { eq: $locale } }
    ) {
      html
      frontmatter {
        slug
        title
        heading
        metatitle
        description
        metadescription
        locale
        region
        type
      }
    }
    featuredLocations: allMarkdownRemark(
      limit: 6
      filter: {
        frontmatter: {
          locale: { eq: $locale }
          type: { eq: "location" }
          featuredPost: { eq: true }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            metatitle
            locale
            region
            type
            url
            slug
            #featuredImage {
            #  childImageSharp {
            #    fluid(maxWidth: 240) {
            #      ...GatsbyImageSharpFluid
            #    }
            #  }
            #}
          }
        }
      }
    }
    hreflangEdges: allMarkdownRemark(
      filter: { frontmatter: { slug: { eq: $slug } } }
    ) {
      edges {
        node {
          frontmatter {
            url
            locale
          }
        }
      }
    }
  }
`
