module.exports = class Post {

    /**
     * @param {any} type
     * @param {any} locale
     * @param {any} region
     * @param {any} url
     * @param {any} mobileUrl
     * @param {any} title
     */
    constructor(type, slug, locale, region, title, url, mobileUrl, order) {
        this.type = type;
        this.locale = locale;
        this.region = region;
        this.title = title;
        this.url = url;
        this.mobileUrl = mobileUrl;
        this.slug = slug;
        this.order = order;
    }

    toLink() {
        return {
            url: this.url,
            mobileUrl: this.mobileUrl,
            locale: this.locale,
            title: this.title,
            slug: this.slug,
            order: this.order
        }
    }
}

