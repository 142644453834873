// @ts-check
const _ = require('lodash');
const PostsGenerator = require('./PostsGenerator');

const PAGE_TYPES = {
    TYPE_HOMEPAGE: 'homepage',
    TYPE_REGION: 'region',
    TYPE_LOCATION: 'location',
    TYPE_ROUTE: 'route',
    TYPE_SINGLE: 'page',
    TYPE_NEWS: 'news'
};

exports.getTopMenuPages = (all) => {
    return _.chain(all.edges)  
        .filter(({ node }) => node.frontmatter.isTopMenuPage === true)
        .orderBy(({ node }) => node.frontmatter.topMenuPageOrder)
        .filter(({node}) => typeof node.frontmatter !== 'undefined')
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter).toLink())
        .value();
}


/**
 * @param {any} all
 */
exports.getHomePages = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_HOMEPAGE)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter))
}

/**
 * @param {any} all
 */
exports.getRegionPosts = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_REGION)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter))
}

/**
 * @param {any} all
 */
exports.getLocationPosts = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_LOCATION)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter));
}

/**
 * @param {any} all
 */
exports.getSinglePages = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_SINGLE)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter))
}

/**
 * @param {any} all
 */
exports.getNewsPosts = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_NEWS)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter))
}

/**
 * @param {any} all
 */
exports.getRoutesPosts = (all) => {
    return all.edges  
        .filter(({ node }) => node.frontmatter.type === PAGE_TYPES.TYPE_ROUTE)
        .map(({ node }) => PostsGenerator.toPost(node.frontmatter))
}


/**
 * @param {any[]} edges
 */
exports.getHrefLangs = (edges) => {
    return (edges && edges.map(edge => { return {
        url: edge.node.frontmatter.url,
        locale: edge.node.frontmatter.locale
      }})) || [];
}