import { Link } from "gatsby"
import React from "react"
import LazyImage from '../LazyImage'

export default function FeaturedIslands({ islands }) {
  
  return (
    <div className="featured-islands">
    {islands
      .map(l => l.node.frontmatter)
      .map(l => {
        return (
          <Link className="featured-island" to={l.url} key={'featured-' + l.slug}>
            <div className="image-gradient"></div>
            <LazyImage className="island-thumbnail" src={`/images/${l.slug}.jpg`} alt={'aktoploika eisitiria ' + l.metatitle} />
            <div className="island-title">{l.title}</div>
          </Link>
        )
      })}
  </div>
  )
}
    